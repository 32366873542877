import axios from 'axios';

export default async function loadConfig() {
    try {
        const response = await axios.get('/config.json');
        if (typeof response.data !== 'object') {
            throw new Error('Invalid config file');
        }
        window.config = response.data;
    } catch (error) {
        window.config = {
            "APP_ENV": process.env.VUE_APP_APP_ENV,
            "API_PROXY": process.env.VUE_APP_API_PROXY,
            "READ_ONLY": process.env.VUE_APP_READ_ONLY,
            "SENTRY_DSN": process.env.VUE_APP_SENTRY_DSN
        };
    }
}
